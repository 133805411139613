import * as React from "react"
import NotFoundImg from "../images/not-found.svg"

const NotFound = (props) => {
    const text = props.text || 'No posts found'
    return (
        <div className="not-found">
            <div className="not-found_img">
                <img src={NotFoundImg} alt="No posts found" />
            </div>
            <p className="not-found-top">
                { text }
            </p>
            <p className="not-found-bottom">
                Please enter another text to search
            </p>
        </div>
    )
}

export default NotFound
