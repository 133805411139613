import * as React from "react"
import Layout from "../../components/layout"
import Seo from "@components/Seo.js"
import FaqList from "../../components/FaqList"
import Breadcrumbs from "../../components/Breadcrumbs"

import { BaseUrlLink} from "../../helpers/baseUrl"

const Faq = () => {
    const pageData = {
        title: 'Frequently Asked Questions on Essay Writing',
        description: `Learn more about our essay writing service by reading answers to common questions asked by users.`,
        keywords: `faq, essay faq, term paper faq, faq book report`
    }

    const steps = [
        {
            name: 'FAQ',
            path: '/support/faq'
        }
    ]
    return (
        <Layout>
            <Seo {...pageData} />
            <div className="faq">
                <div className="faq-intro wrapper">
                    <Breadcrumbs steps={steps}/>
                    <h1 className="page-intro__title">Frequently Asked Questions</h1>
                </div>
                <div className="faq-content">
                    <div className="faq-content__wrapper wrapper">
                        <div className="faq-list__wrapper">
                            <FaqList />
                        </div>

                        <div className="faq-banner__wrapper">
                            <div className="faq-banner">
                                <h2 className="faq-banner-title">
                                    If your question is not resolved
                                </h2>
                                <a
                                    href={`${BaseUrlLink}/support/ticket/new`}
                                    className="button button-primary"
                                >
                                    Ask support
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Faq